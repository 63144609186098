import type { Environment } from './types';
import { sharedDevEnv, sharedDevEuEnv, sharedJuniperUkEnv } from './shared';

export const env: Environment = {
  ...sharedDevEnv,
  ...sharedJuniperUkEnv,
  ...sharedDevEuEnv,
  adminAppUrl: 'https://admin.dev.myjuniper.co.uk',
  logoUrl: 'https://assets.myjuniper.co.uk/juniper-logo.svg',
};
